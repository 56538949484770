import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';


function base64ToArrayBuffer(base64) 
{
    const binaryStr = atob(base64);
    const len = binaryStr.length;
    const arrayBuffer = new ArrayBuffer(len);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < len; i++) {
        view[i] = binaryStr.charCodeAt(i);
    }
    return arrayBuffer;
}

function mergeGeometries(object3D) {
    const geometries = [];
    object3D.traverse((child) => {
      if (child.isMesh) {
        const geom = child.geometry.clone();
        if (child.matrixAutoUpdate) child.updateMatrix();
        geom.applyMatrix4(child.matrix);
        geometries.push(geom);
      }
    });
    // Merge all geometries into one
    const mergedGeometry = mergeBufferGeometries(geometries, true);
    return mergedGeometry;
  }

export async function loadMesh(file, base64) 
{
    const extension = file.name.split('.').pop().toLowerCase();
  
    // Remove the data URL prefix if present
    const base64Data = base64.split(',')[1];
  
    const material = new THREE.MeshStandardMaterial({ color: 0xaaaaaa });
  
    let mesh;
  
    switch (extension) 
    {
      case 'obj':
        {
          const loader = new OBJLoader();
          // Decode base64 to string
          const objText = atob(base64Data);
          // Parse the OBJ data
          const object3D = loader.parse(objText);
          // Merge all geometries into one mesh
          const geometry = mergeGeometries(object3D);
          mesh = new THREE.Mesh(geometry, material);
        }
        break;
  
      case 'stl':
        {
          const loader = new STLLoader();
          // Convert base64 to ArrayBuffer
          const stlArrayBuffer = base64ToArrayBuffer(base64Data);
          // Parse the STL data
          const geometry = loader.parse(stlArrayBuffer);
          mesh = new THREE.Mesh(geometry, material);
        }
        break;
  
      case 'glb':
      case 'gltf':
        {
          const loader = new GLTFLoader();
          // Convert base64 to ArrayBuffer
          const gltfArrayBuffer = base64ToArrayBuffer(base64Data);
          // Parse the GLTF data asynchronously
          mesh = await new Promise((resolve, reject) => {
            loader.parse(
              gltfArrayBuffer,
              '',
              (gltf) => {
                // Merge all geometries into one mesh
                const geometry = mergeGeometries(gltf.scene);
                const mesh = new THREE.Mesh(geometry, material);
                resolve(mesh);
              },
              (error) => {
                console.error('Error loading glTF model:', error);
                reject(error);
              }
            );
          });
        }
        break;
  
      default:
        console.error('Unsupported file type:', extension);
        return null;
    }
  
    return mesh;
  }