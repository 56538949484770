// vuex.js state for Boxurizer
import * as THREE from 'three'
import { loadMesh } from '@/utils/loader.js'


const WINDOW_ITEM = 'item'
const WINDOW_CONTAINER = 'container'
const WINDOW_INFILL = 'infill'
const WINDOW_RENDER = 'render'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_ITEM

const DIRECTION_RIGHT = 2
const DIRECTION_LEFT = 3
const DIRECTION_TOP = 4
const DIRECTION_BOTTOM = 5
const DIRECTION_FRONT = 0
const DIRECTION_BACK = 1

const CONVERSION_SIZE = 1000.0

const CAMERA_PERSPECTIVE_DEFAULT = DIRECTION_FRONT

const CONTAINER_SPACING_DEFAULT = 15

function newSize() {
    return {
        width: 0,
        height: 0,
        depth: 0
    }
}

function convertSize(direction, size) {
    let newSize = null
    switch (direction) {
        case DIRECTION_RIGHT:
        case DIRECTION_LEFT:
            newSize = {
                width: size.height,
                height: size.width,
                depth: size.depth
            }
            break
        case DIRECTION_TOP:
        case DIRECTION_BOTTOM:
            newSize = {
                width: size.width,
                height: size.height,
                depth: size.depth
            }
            break
        case DIRECTION_FRONT:
        case DIRECTION_BACK:
            newSize = {
                width: size.width,
                height: size.depth,
                depth: size.height
            }
            break
    }
    return newSize
}

function newContainerOption(label, width, height, depth) {
    return {
        label,
        width,
        height,
        depth
    }
}

export const boxurizer = {
    namespaced: true,
    state: {
        window: WINDOW_DEFAULT,
        camera: {
            perspective: CAMERA_PERSPECTIVE_DEFAULT
        },
        item: {
            visible: true,
            mesh: null,
            geometry: null,
            size: newSize(),
            direction: DIRECTION_RIGHT,
            version: 0
        },
        container: {
            visible: true,
            columns: 1,
            rows: 1,
            selection: null,
            options: [
                newContainerOption("Small Box", 300, 200, 150),
                newContainerOption("Medium Box", 400, 300, 200),
                newContainerOption("Large Box", 600, 400, 300),
                newContainerOption("Huge Box", 800, 600, 400),
                newContainerOption("Paket S", 350, 250, 100),
                newContainerOption("Paket M", 600, 300, 150),
                newContainerOption("Paket L", 900, 600, 600),
                newContainerOption("Paket XL", 1200, 600, 600)
            ],
            // mm, probably needs to be editable (changes with more heavy items)
            spacing: CONTAINER_SPACING_DEFAULT, 
            sides: {
                highlight: null,
                selection: {}
            }
        },
        infill: {
            visible: true,
            list: [],
            selection: null
        }
    },
    mutations: {
        SET_WINDOW(state, window) {
            state.window = window
        },

        SET_CAMERA_PERSPECTIVE(state, perspective) {
            state.camera.perspective = perspective
        },

        RESET_ITEM(state) {
            state.item.mesh = null
            state.item.geometry = null
            state.item.size = newSize()
            state.item.direction = DIRECTION_TOP
            state.item.version += 1
        },
        SET_ITEM_VISIBLE(state, visible) {
            state.item.visible = visible
        },
        SET_ITEM_MESH(state, mesh) {
            state.item.mesh = mesh
            state.item.geometry = mesh.geometry
            state.item.version += 1
        },
        SET_ITEM_MESH_ONLY(state, mesh) {
            state.item.mesh = mesh
            state.item.version += 1
        },
        SET_ITEM_SIZE(state, { width, height, depth }) 
        {
            const round = (value) => {
                return Math.round(value * 10) / 10
            }

            const newSize = convertSize(state.item.direction, {
                width: round(width),
                height: round(height),
                depth: round(depth)
            })
        
            const sizeChanged = ['width', 'height', 'depth'].some(
                (dim) => state.item.size[dim] !== newSize[dim]
            )
        
            if (sizeChanged) {
                state.item.size = newSize
                state.item.version += 1
            }
        },
        SET_ITEM_DIRECTION(state, direction) 
        {
            if (state.item.direction != direction) {
                state.item.direction = direction
                state.item.version += 1
            }
        },

        RESET_CONTAINER(state) {
            state.container.rows = 1
            state.container.columns = 1
            state.container.selection = null
            state.container.spacing = CONTAINER_SPACING_DEFAULT
            state.container.sides.highlight = null
            state.container.sides.selection = {}
            state.container.infill = []
        },
        SET_CONTAINER_VISIBLE(state, visible) {
            state.container.visible = visible
        },
        SET_CONTAINER_COLUMNS(state, columns) {
            state.container.columns = columns
        },
        SET_CONTAINER_ROWS(state, rows) {
            state.container.rows = rows
        },
        SET_CONTAINER_SELECTION(state, selection) {
            state.container.selection = selection
        },
        SET_CONTAINER_OPTIONS(state, options) {
            state.container.options = options

            const selectionExists = state.container.options.some(
                (option) => option.label === state.container.selection
            )
            if (!selectionExists) state.container.selection = null
        },
        ADD_CONTAINER_OPTION(state, { label, width, height, depth }) {
            const containerOption = newContainerOption(label, width, height, depth)
            state.container.options.push(containerOption)
        },
        REMOVE_CONTAINER_OPTION(state, label) {
            state.container.options = state.container.options.filter(
                (option) => option.label !== label
            )
        },
        SET_CONTAINER_SPACING(state, spacing) {
            state.container.spacing = spacing
        },
        SET_CONTAINER_SIDES_HIGHLIGHT(state, highlight) {
            state.container.sides.highlight = highlight
        },
        SET_CONTAINER_SIDES_SELECTION(state, selection) {
            state.container.sides.selection = selection
        },

        RESET_INFILL(state) {
            state.infill.list = []
        },
        ADD_INFILL(state, infill) {
            state.infill.list.push(infill)
        },
        REMOVE_INFILL(state, infillId) {
            state.infill.list = state.infill.list.filter(infill => infill.id !== infillId)
            if (state.infill.selection == infillId) state.infill.selection = null
        },
        SET_INFILL_VISIBLE(state, visible) {
            state.infill.visible = visible
        },
        SET_INFILL_SELECTION(state, selection) {
            state.infill.selection = selection
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowItem: () => WINDOW_ITEM,
        keyWindowContainer: () => WINDOW_CONTAINER,
        keyWindowInfill: () => WINDOW_INFILL,
        keyWindowRender: () => WINDOW_RENDER,
        keyWindowExport: () => WINDOW_EXPORT,
        keyDirectionRight: () => DIRECTION_RIGHT,
        keyDirectionLeft: () => DIRECTION_LEFT,
        keyDirectionTop: () => DIRECTION_TOP,
        keyDirectionBottom: () => DIRECTION_BOTTOM,
        keyDirectionFront: () => DIRECTION_FRONT,
        keyDirectionBack: () => DIRECTION_BACK,
        keyConversionSize: () => CONVERSION_SIZE,
        keyCameraPerspectiveDefault: () => CAMERA_PERSPECTIVE_DEFAULT,
        keyContainerSpacingDefault: () => CONTAINER_SPACING_DEFAULT,
        isWindowDefault: state => state.window === WINDOW_DEFAULT,
        isWindowItem: state => state.window === WINDOW_ITEM,
        isWindowContainer: state => state.window === WINDOW_CONTAINER,
        isWindowInfill: state => state.window === WINDOW_INFILL,
        isWindowRender: state => state.window === WINDOW_RENDER,
        isWindowExport: state => state.window === WINDOW_EXPORT,
        isCameraPerspectiveDefault: state => state.camera.perspective === CAMERA_PERSPECTIVE_DEFAULT, 
        isCameraPerspectiveRight: state => state.camera.perspective === DIRECTION_RIGHT,
        isCameraPerspectiveLeft: state => state.camera.perspective === DIRECTION_LEFT,
        isCameraPerspectiveTop: state => state.camera.perspective === DIRECTION_TOP,
        isCameraPerspectiveBottom: state => state.camera.perspective === DIRECTION_BOTTOM,
        isCameraPerspectiveFront: state => state.camera.perspective === DIRECTION_FRONT,
        isCameraPerspectiveBack: state => state.camera.perspective === DIRECTION_BACK,
        isItemVisible: state => state.item.visible,
        isContainerVisible: state => state.container.visible,
        isInfillVisible: state => state.infill.visible,
        isInfillSelected: state => state.infill.selection !== null,
        hasItem: state => state.item.mesh !== null,
        hasContainer: state => state.container.selection !== null,
        hasInfill: state => state.infill.list.length > 0,

        getActiveWindow: state => state.window,
        getCameraPerspective: state => state.camera.perspective,

        getItemMesh: state => state.item.mesh,
        getItemGeometry: state => state.item.geometry,
        getItemSize: state => {
            return convertSize(state.item.direction, state.item.size)
        },
        getItemSizeMagnitude: state => {
            return Math.max(
                state.item.size.width, 
                state.item.size.height,
                state.item.size.depth
            )
        },
        getItemDirection: state => state.item.direction,
        getItemVersion: state => state.item.version,

        getContainerColumns: state => state.container.columns,
        getContainerRows: state => state.container.rows,
        getContainerSlots: (state, getters) => {
            // 
            const slots = []

            // get needed state variables
            const size = getters.getItemSize
            const columns = state.container.columns
            const rows = state.container.rows
            const spacing = state.container.spacing

            // calculate total width and height to center items around (0, 0, 0)
            const totalWidth = (columns - 1) * spacing + columns * size.width
            const totalDepth = (rows - 1) * spacing + rows * size.depth

            // iterate over columns
            for (let column = 0; column < columns; column++) 
            {
                // iterate over rows
                for (let row = 0; row < rows; row++) 
                {
                    // calculate the position to center items around (0, 0, 0)
                    const x = column * (size.width + spacing) - (totalWidth / 2) + size.width / 2
                    const z = row * (size.depth + spacing) - (totalDepth / 2) + size.depth / 2

                    // add current item position as slot
                    slots.push({
                        x, 
                        y: 0, 
                        z
                    })
                }
            }

            return slots
        },
        getContainerSelection: state => state.container.selection,
        getContainerOptions: state => state.container.options,
        getContainerOptionSelected: state => { // TODO rename to selected (without option)
            if (state.container.selection === null) return null
            return Object.values(state.container.options).find(
                option => option.label === state.container.selection
            ) || null
        },
        getContainerSpacing: state => state.container.spacing,
        getContainerSidesHighlight: state => state.container.sides.highlight,
        getContainerSidesSelection: state => state.container.sides.selection,

        getInfillList: state => state.infill.list,
        getInfillCount: state => state.infill.list.length,
        getInfillSelection: state => state.infill.selection,
        getInfillSelected: state => {
            if (state.infill.selection === null) return null
            return state.infill.list.find(
                infill => infill.id === state.infill.selection
            ) || null
        }
    },
    actions: {
        setActiveWindow({ commit }, window) {
            commit('SET_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToItem({ commit }) {
            commit('SET_WINDOW', WINDOW_ITEM)
        },
        setActiveWindowToContainer({ commit }) {
            commit('SET_WINDOW', WINDOW_CONTAINER)
        },
        setActiveWindowToInfill({ commit }) {
            commit('SET_WINDOW', WINDOW_INFILL)
        },
        setActiveWindowToRender({ commit }) {
            commit('SET_WINDOW', WINDOW_RENDER)
        },
        setActiveWindowToExport({ commit }) {
            commit('SET_WINDOW', WINDOW_EXPORT)
        },

        setCameraPerspective({ commit }, perspective) {
            commit('SET_CAMERA_PERSPECTIVE', perspective)
        },
        setCameraPerspectiveToDefault({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', CAMERA_PERSPECTIVE_DEFAULT)
        },
        setCameraPerspectiveToRight({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_RIGHT)
        },
        setCameraPerspectiveToLeft({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_LEFT)
        },
        setCameraPerspectiveToTop({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_TOP)
        },
        setCameraPerspectiveToBottom({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BOTTOM)
        },
        setCameraPerspectiveToFront({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_FRONT)
        },
        setCameraPerspectiveToBack({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BACK)
        },

        clearItem({ commit }) {
            commit('RESET_INFILL')
            commit('RESET_CONTAINER')
            commit('RESET_ITEM')
        },
        importItem({ getters, commit, dispatch }, { file, base64 }) 
        {
            // import item
            return new Promise((resolve, reject) => {
                loadMesh(file, base64)
                    .then((mesh) => {

                        // clear existing item
                        dispatch('clearItem')

                        try {
                            // compute bounding box 
                            mesh.geometry.computeBoundingBox()
        
                            // translate by center of bounding box
                            mesh.geometry.center()
        
                            // get the size of the bounding box
                            const size = new THREE.Vector3()
                            mesh.geometry.boundingBox.getSize(size)
        
                            // convert size from meters to millimeters
                            const width = size.x * getters.keyConversionSize
                            const height = size.y * getters.keyConversionSize
                            const depth = size.z * getters.keyConversionSize
        
                            // normalize the geometry size
                            const scaleX = 1 / width
                            const scaleY = 1 / height
                            const scaleZ = 1 / depth
        
                            // apply the scale to the geometry
                            mesh.geometry.scale(scaleX, scaleY, scaleZ)
        
                            // commit item to store
                            commit('SET_ITEM_MESH', mesh)
                            commit('SET_ITEM_SIZE', { width, height, depth })

                            // 
                            dispatch('setCameraPerspectiveToDefault')

                            // resolve the promise to indicate success
                            resolve()
                        } catch (error) {
                            reject(error)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        setItemVisible({ commit }, visible) {
            commit('SET_ITEM_VISIBLE', visible)
        },
        setItemSize({ commit }, { width, height, depth }) {
            // TODO adjust to current direction
            commit('SET_ITEM_SIZE', { width, height, depth })
        },
        setItemDirection({ getters, commit }, direction) {

            // 
            const mesh = getters.getItemMesh
            mesh.geometry = getters.getItemGeometry.clone()

            switch (direction) 
            {
                case DIRECTION_RIGHT:
                    mesh.geometry.rotateY(-90 * (Math.PI / 180))
                    break
                case DIRECTION_LEFT:
                    mesh.geometry.rotateY( 90 * (Math.PI / 180))
                    break
                case DIRECTION_TOP:
                    // default case
                    break
                case DIRECTION_BOTTOM:
                    mesh.geometry.rotateZ(180 * (Math.PI / 180))
                    break
                case DIRECTION_FRONT:
                    mesh.geometry.rotateX(-90 * (Math.PI / 180))
                    break
                case DIRECTION_BACK:
                    mesh.geometry.rotateX( 90 * (Math.PI / 180))
                    break
            }

            commit('SET_ITEM_DIRECTION', direction)
            commit('SET_ITEM_MESH_ONLY', mesh)
        },

        clearContainer({ commit }) {
            commit('RESET_INFILL')
            commit('RESET_CONTAINER')
        },
        setContainerVisible({ commit }, visible) {
            commit('SET_CONTAINER_VISIBLE', visible)
        },
        setContainerColumns({ commit }, columns) {
            commit('SET_CONTAINER_COLUMNS', columns)
        },
        setContainerRows({ commit }, rows) {
            commit('SET_CONTAINER_ROWS', rows)
        },
        setContainerSelection({ commit }, selection) {
            commit('SET_CONTAINER_SELECTION', selection)
        },
        setContainerOptions({ commit }, options) {
            commit('SET_CONTAINER_OPTIONS', options)
        },
        addContainerOption({ commit }, { label, width, height, depth }) {
            commit('ADD_CONTAINER_OPTION', { label, width, height, depth })
        },
        removeContainerOption({ commit }, label) {
            commit('REMOVE_CONTAINER_OPTION', label)
        },
        setContainerSpacing({ commit }, spacing) {
            commit('SET_CONTAINER_SPACING', spacing)
        },
        setContainerSidesHighlight({ commit }, highlight) {
            commit('SET_CONTAINER_SIDES_HIGHLIGHT', highlight)
        },
        setContainerSidesSelection({ commit }, selection) {
            commit('SET_CONTAINER_SIDES_SELECTION', selection)
        },

        clearInfill({ commit }) {
            commit('RESET_INFILL')
        },
        addInfill({ commit }, infill) {
            commit('ADD_INFILL', infill)
        },
        deleteInfill({ commit }, infillId) {
            if (infill) commit('REMOVE_INFILL', infillId)
        },
        deleteSelectedInfill({ commit, getters }) {
            const selection = getters.getInfillSelection
            if (selection) commit('REMOVE_INFILL', selection)
        },
        setInfillVisible({ commit }, visible) {
            commit('SET_INFILL_VISIBLE', visible)
        },
        setInfillSelection({ commit }, selection) {
            commit('SET_INFILL_SELECTION', selection)
        },
        calculateInfill({ commit }) {
            // TODO
        }
    }
}